<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs11 sm11 md10>
        <v-layout wrap justify-center pt-16>
          <v-flex xs12 text-left>
            <span
              style="
                color: #000000;
                font-family: poppinsmedium;
                font-size: 20px;
              "
              >Related Courses
            </span>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5 pb-16>
          <v-flex
            xs12
            sm6
            md4
            pa-xs-2
            pa-sm-2
            pa-md-8
            pa-lg-8
            pa-xl-8
            v-for="(list, i) in relatedCourse"
            :key="i"
          >
            <v-card elevation="10">
              <v-layout wrap>
                <v-flex xs12>
                  <v-img
                    :src="mediaURL + list.frontImage"
                    :lazy-src="mediaURL + list.frontImage"
                    :alt="list.frontImage"
                    eager
                    ><template v-slot:placeholder>
                      <ImageLoader /> </template></v-img
                ></v-flex>
                <v-flex xs12 px-5 py-5 text-left>
                  <span
                    style="
                      color: #3cb043;
                      font-family: poppinsmedium;
                      font-size: 20px;
                    "
                  >
                  US ${{ list.offerPrice }} &nbsp;</span
                  >
                  <span
                    style="
                      color: #7f7f7f;
                      font-family: poppinslight;
                      font-size: 12px;
                    "
                    >(Inclusive of all Taxes)</span
                  >
                </v-flex>
                <v-flex xs12 px-5 text-left>
                  <span
                    style="
                      font-family: poppinsbold;
                      color: black;
                      font-size: 18px;
                    "
                    >{{ list.courseName }}</span
                  >
                </v-flex>
                <v-flex xs12 text-left px-5 pt-2 pb-4>
                  <span
                    style="
                      font-family: poppinslight;
                      color: black;
                      font-size: 13px;
                    "
                    v-html="list.description"
                  ></span>
                </v-flex>
                <v-flex xs12 text-right px-5 pt-2 pb-10>
                  <v-btn
                    dark
                    small
                    color="#3CB043"
                    :ripple="false"
                    :to="'/courseDetails?id=' + list._id"
                    ><span
                      style="
                        font-family: opensanssemibold;
                        text-transform: none;
                      "
                      >Details</span
                    ></v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      relatedCourse: [],
      items: [
        {
          img: require("../../../assets/images/course1.png"),
          rate: "$1000",
          name: "Lead Auditor Course : Environmental Safety Management Systems",
          description:
            "Learn how to establish, implement, and maintain an Environmental Management System.",
        },
        {
          img: require("../../../assets/images/course2.png"),
          rate: "$1000",
          name: "Lead Auditor Course: Food Safety Management Systems",
          description:
            "Understand how to establish, implement, and maintain a Food Safety Management System",
        },
        {
          img: require("../../../assets/images/course3.png"),
          rate: "$1000",
          name: "Lead Auditor Course: Quality Management Systems",
          description:
            "Learn how to establish, implement, maintain, and continually improve an organization’s occupational ",
        },
      ],
    };
  },
  beforeMount() {
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/course/relatedCourse/list",
        method: "POST",
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.relatedCourse = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>