<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center>
      <v-flex xs11 sm10>
        <v-layout wrap v-if="courseView">
          <v-flex xs12 sm12 md8 pt-8>
            <v-img :src="mediaURL + courseView.image"> </v-img>
            <v-layout wrap>
              <v-flex xs12 text-left py-8>
                <span
                  style="
                    font-family: poppinsbold;
                    color: #000000;
                    font-size: 24px;
                  "
                  >{{ courseView.courseName }}</span
                >
              </v-flex>
              <v-flex xs12 pr-5>
                <v-tabs
                  v-model="tab"
                  background-color="#DFFFDBCC"
                  color="#3CB043"
                  dark
                  grow
                >
                  <v-tab v-for="(item, i) in items" :key="i">
                    <span
                      style="font-family: poppinsmedium"
                      :style="tab == i ? 'color: black' : 'color: #635E5E'"
                    >
                      {{ item }}</span
                    >
                  </v-tab>
                  <v-tab-item>
                    <v-layout wrap justify-start pb-10 pt-5>
                      <v-flex xs12 text-left py-5>
                        <span
                          style="
                            color: #000000;
                            font-family: poppinssemibold;
                            font-size: 20px;
                          "
                          >Course Modules</span
                        >
                      </v-flex>
                      <v-flex
                        xs12
                        v-for="(list, l) in modules"
                        :key="l"
                        text-left
                      >
                        <span
                          style="
                            color: #000000;
                            font-family: poppinsmedium;
                            font-size: 17px;
                          "
                          >Module {{ list.moduleNo }} :
                          {{ list.moduleName }}</span
                        >
                        <v-flex xs12 py-5 px-8>
                          <span
                            style="
                              color: #000000;
                              font-family: poppinsregular;
                              font-size: 14px;
                            "
                            v-html="list.moduleDetails"
                          ></span>
                        </v-flex>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item>
                    <v-layout wrap pb-16>
                      <v-flex xs12 text-left py-10>
                        <span
                          style="
                            color: #000000;
                            font-family: poppinssemibold;
                            font-size: 20px;
                          "
                          >Learning Outcomes:</span
                        >
                      </v-flex>
                      <v-flex xs12 px-8 text-left>
                        <span
                          style="
                            color: #000000;
                            font-family: poppinsregular;
                            font-size: 14px;
                          "
                          v-html="courseView.learningOutComes"
                        ></span>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item>
                    <v-layout wrap pb-10 pt-5>
                      <v-flex xs12 text-left py-5>
                        <span
                          style="
                            color: #000000;
                            font-family: poppinssemibold;
                            font-size: 20px;
                          "
                          >Who Should Attend :</span
                        >
                      </v-flex>
                      <v-flex xs12 py-2 px-8 text-left>
                        <span
                          style="
                            color: #000000;
                            font-family: poppinsregular;
                            font-size: 14px;
                          "
                          v-html="courseView.whoShouldAttend"
                        ></span>
                      </v-flex>
                      <v-flex xs12 text-left py-5>
                        <span
                          style="
                            color: #000000;
                            font-family: poppinssemibold;
                            font-size: 20px;
                          "
                          >Assessment & Certification</span
                        >
                      </v-flex>
                      <v-flex xs12 py-2 px-8 text-left>
                        <span
                          style="
                            color: #000000;
                            font-family: poppinsregular;
                            font-size: 14px;
                          "
                          v-html="courseView.assesmentAndEvaluation"
                        ></span>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                </v-tabs>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md4 pt-8>
            <v-layout wrap justify-start>
              <v-flex xs1 class="hidden-sm-and-down">
                <v-divider vertical></v-divider>
              </v-flex>
              <v-flex xs11>
                <v-layout
                  wrap
                  justify-center
                  py-5
                  style="background-color: #f9f9f9"
                >
                  <v-flex xs12 py-5 text-left pl-5>
                    <v-layout wrap>
                      <v-flex xs2 sm1 md1 pa-1>
                        <v-img src="../../../assets/images/Clock.png"></v-img>
                      </v-flex>
                      <v-flex xs10 sm11 md11 px-5>
                        <span
                          style="
                            font-family: poppinsmedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >{{ courseView.overView.modeOfEducation }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 pl-5 pb-5>
                    <v-layout wrap justify-start>
                      <v-flex xs2 sm1 md1 pa-1>
                        <v-img src="../../../assets/images/Lock.png"></v-img>
                      </v-flex>
                      <v-flex xs10 sm11 md11 text-left px-5>
                        <span
                          style="
                            font-family: poppinsmedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >{{ courseView.overView.security }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 pb-5 text-left pl-5>
                    <v-layout wrap>
                      <v-flex xs2 sm1 md1 pa-1>
                        <v-img
                          src="../../../assets/images/Microphone.png"
                        ></v-img>
                      </v-flex>
                      <v-flex xs10 sm11 md11 px-5>
                        <span
                          style="
                            font-family: poppinsmedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >{{ courseView.overView.language }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 pb-5 text-left pl-5>
                    <v-layout wrap>
                      <v-flex xs2 sm1 md1 pa-1>
                        <v-img
                          src="../../../assets/images/Certificate.png"
                        ></v-img>
                      </v-flex>
                      <v-flex xs10 sm11 md11 px-5>
                        <span
                          style="
                            font-family: poppinsmedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >{{ courseView.overView.certification }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 pb-5 text-left pl-5>
                    <v-layout wrap>
                      <v-flex xs2 sm1 md1 pa-1>
                        <v-img src="../../../assets/images/Globe.png"></v-img>
                      </v-flex>
                      <v-flex xs10 sm11 md11 px-5>
                        <span
                          style="
                            font-family: poppinsmedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >{{ courseView.overView.mode }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 pb-5 text-left pl-5>
                    <v-layout wrap>
                      <v-flex xs2 sm1 md1 pa-1>
                        <v-img src="../../../assets/images/Medal.png"></v-img>
                      </v-flex>
                      <v-flex xs10 sm11 md11 px-5>
                        <span
                          style="
                            font-family: poppinsmedium;
                            color: #000000;
                            font-size: 14px;
                          "
                          >{{ courseView.overView.cpdPoints }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs10 sm10 text-center py-5>
                    <v-btn color="rgba(60, 176, 67, 0.06)" depressed block>
                      <span
                        style="
                          color: #3cb043;
                          font-family: poppinsbold;
                          font-size: 18px;
                        "
                      >
                        US ${{ courseView.offerPrice }}</span
                      >
                    </v-btn>
                  </v-flex>
                  <v-flex xs10 sm10 text-center pb-5 pt-2 v-if="inCart==true">
                    <v-btn color="#3CB043" depressed block :to="'/cart'">
                      <span
                        style="
                          color: #ffffff;
                          font-family: opensanssemibold;
                          text-transform: none;
                        "
                        >In Cart</span
                      >
                    </v-btn>
                  </v-flex>
                  <v-flex xs10 sm10 text-center pb-5 pt-2 v-else>
                    <v-btn color="#3CB043" depressed block @click="addCart()">
                      <span
                        style="
                          color: #ffffff;
                          font-family: opensanssemibold;
                          text-transform: none;
                        "
                        >Enroll Course</span
                      >
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap pt-15 pl-8>
              <v-flex xs12 text-left pb-4>
                <span
                  style="
                    color: #000000;
                    font-family: poppinsmedium;
                    font-size: 20px;
                  "
                  >Popular Courses
                </span>
              </v-flex>
              <v-flex xs12 v-for="(pop, p) in popular" :key="p" pt-5>
                <v-layout wrap>
                  <v-flex xs4 sm4 md3 pt-2>
                    <v-img
                      :src="mediaURL + pop.frontImage"
                      :lazy-src="mediaURL + pop.frontImage"
                      :alt="pop.frontImage"
                      eager
                      ><template v-slot:placeholder>
                        <ImageLoader /> </template
                    ></v-img>
                  </v-flex>
                  <v-flex xs8 sm8 md9 pl-3 text-left>
                    <v-layout wrap>
                      <v-flex xs12>
                        <span
                          style="
                            color: #000000;
                            font-family: poppinsmedium;
                            font-size: 14px;
                          "
                          >{{ pop.courseName }}</span
                        >
                      </v-flex>
                      <v-flex xs12 pt-2>
                        <span
                          style="
                            color: #3cb043;
                            font-family: poppinssemibold;
                            font-size: 16px;
                          "
                        >
                        US ${{ pop.offerPrice }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 pt-5>
                    <v-divider></v-divider>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap style="background-color: #f9f9f9">
      <v-flex xs12>
        <RelatedCourse />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import RelatedCourse from "./relatedCourse";
export default {
  components: {
    RelatedCourse,
  },
  data() {
    return {
      tab: null,
      items: ["Curriculum", "Outcomes", "Audience & Certification"],
      courseView: "",
      modules: [],
      popular: [],
      guestid:"",
      appLoading: false,
      ServerError: false,
      cart:[],
      inCart:"",
    };
  },
  computed: {
    appLogin() {
      if (this.$store.state.isLoggedIn) return this.$store.state.isLoggedIn;
      else return null;
    },
  },
  beforeMount() {
    this.getData();
    this.getPopular();
  },

  methods: {
    getData() {
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else this.guestid = localStorage.getItem("userId");
      this.appLoading = true;
      axios({
        url: "/course/view/details",
        method: "POST",
        data: {
          id: this.$route.query.id,
          guestid:this.guestid
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          this.courseView = response.data.data;
          this.modules = response.data.modules;
          this.inCart=response.data.inCart
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getPopular() {
      this.appLoading = true;
      axios({
        url: "/course/popularCourse/list",
        method: "POST",
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.popular = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addCart(){
      this.appLoading = true;
      var headers1 = {};
      if (this.appLogin) {
        headers1 = {
          token: localStorage.getItem("token"),
        };
      } else this.guestid = localStorage.getItem("userId");
      axios({
        url: "/cart/add",
        method: "POST",
        data: {
          courseId: this.$route.query.id,
          guestid:this.guestid
        },
        headers: headers1,
      })
        .then((response) => {
          this.appLoading = false;
          this.cart = response.data.data;
          this.$store.commit("changeCart", response.data.cartLength);
          this.$router.push("/cart")
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    }
  },
};
</script>